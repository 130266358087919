var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addTicket)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('input-form',{attrs:{"placeholder":"Ticket Name","validate":'required|max:30',"name":"Ticket name","label":'Ticket Name',"limit":30},model:{value:(_vm.ticket.name),callback:function ($$v) {_vm.$set(_vm.ticket, "name", $$v)},expression:"ticket.name"}})],1),_c('b-col',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('label',{attrs:{"for":"duration-group"}},[_vm._v("Duration")]),_c('b-input-group',{attrs:{"id":"duration-group"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-dropdown',{staticClass:"selectWithInputAppend",attrs:{"text":_vm.type ? _vm.type : 'Pick duration type'}},_vm._l((_vm.allDurationList),function(i,keyType){return _c('b-dropdown-item',{key:keyType,on:{"click":function($event){_vm.ticket.duration_list_id = i.id;
                                         _vm.type = i.name}}},[_vm._v(" "+_vm._s(i.name)+" ")])}),1)]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"label":'Duration',"placeholder":'duration',"validate":'required',"name":"duration"},model:{value:(_vm.ticket.duration),callback:function ($$v) {_vm.$set(_vm.ticket, "duration", $$v)},expression:"ticket.duration"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"md":"4"}},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":"EGP price","rules":{ required: true, regex: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Price'}},[_c('b-input-group',{attrs:{"append":"EGP"}},[_c('b-form-input',{class:[{ 'is-invalid': errors.length > 0 }],attrs:{"placeholder":"000.00"},model:{value:(_vm.ticket.price_egp),callback:function ($$v) {_vm.$set(_vm.ticket, "price_egp", $$v)},expression:"ticket.price_egp"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mb-5 pt-4 mt-3 text-center",attrs:{"md":"4"}},[_c('b-form-checkbox',{staticClass:"custom-checkbox-color-check mb-2 mr-sm-2 mb-sm-0",attrs:{"type":"checkbox","color":"warning"},model:{value:(_vm.selectedEGP),callback:function ($$v) {_vm.selectedEGP=$$v},expression:"selectedEGP"}},[_vm._v(" Discounted Price ")])],1),_c('b-col',{staticClass:"mb-3",attrs:{"md":"4"}},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":"Discounted EGP price","rules":{ regex: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, required: _vm.selectedEGP }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Discounted Price'}},[_c('b-input-group',{attrs:{"append":"EGP"}},[_c('b-form-input',{class:[{ 'is-invalid': errors.length > 0}],attrs:{"placeholder":"000.00","disabled":!_vm.selectedEGP},model:{value:(_vm.ticket.discount_price_egp),callback:function ($$v) {_vm.$set(_vm.ticket, "discount_price_egp", $$v)},expression:"ticket.discount_price_egp"}})],1),(!_vm.ticket.discount_price_egp)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),(Number(_vm.ticket.discount_price_egp) > Number(_vm.ticket.price_egp))?_c('small',{staticClass:"text-danger"},[_vm._v(" More than price ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mb-3",attrs:{"md":"12"}},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":"Conditions","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Conditions"}},[_c('b-form-textarea',{class:[{ 'is-invalid': errors.length > 0 }],attrs:{"placeholder":"Write your conditions in bullet points","rows":"2"},on:{"focus":function($event){_vm.ticket.conditions = _vm.ticket.conditions === '' ? '• ' : _vm.ticket.conditions},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.ticket.conditions += '• '}},model:{value:(_vm.ticket.conditions),callback:function ($$v) {_vm.$set(_vm.ticket, "conditions", $$v)},expression:"ticket.conditions"}}),_c('div',{staticClass:"d-flex justify-content-between"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])],1)]}}],null,true)})],1)],1)],1),_c('b-col',{staticClass:"mb-3",attrs:{"lg":"6"}},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":"Description","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Description"}},[_c('b-form-textarea',{class:[{ 'is-invalid': errors.length > 0 }],attrs:{"placeholder":"Write a brief description","rows":"4"},model:{value:(_vm.ticket.details),callback:function ($$v) {_vm.$set(_vm.ticket, "details", $$v)},expression:"ticket.details"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-5"},[_c('cropper-images',{attrs:{"label":"Upload Photos","removeLoadingUi":_vm.removeLoadingUi,"progressLoading":_vm.progressBar,"images":_vm.ticket.images,"type":"ticket_image"},on:{"cropper-save":_vm.saveGalleryImage,"remove-image":_vm.removeGalleryImage}})],1)],1),(_vm.typeOfModal != 'view')?_c('b-row',[_c('b-col',{staticClass:"mt-4",attrs:{"md":"12"}},[(_vm.typeOfModal == 'add')?_c('div',{staticClass:"d-flex justify-content-center"},[(!_vm.requestLoading)?_c('b-button',{staticClass:"button-orange-modal",attrs:{"type":"submit"}},[_c('i',{staticClass:"las la-plus"})]):_c('b-button',{staticClass:"button-orange-modal"},[_c('spinner-loading')],1)],1):_vm._e(),(_vm.typeOfModal == 'edit')?_c('div',{staticClass:"d-flex justify-content-center"},[(!_vm.requestLoading)?_c('b-button',{staticClass:"button-blue-modal",attrs:{"type":"submit"}},[_c('i',{staticClass:"las la-pen"})]):_c('b-button',{staticClass:"button-blue-modal"},[_c('spinner-loading')],1)],1):_vm._e()])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }